import PropTypes from 'prop-types'
import styled from 'styled-components'
import wpBlockStyles from '../../../../vendor/blockLibrary/wpBlockStyles'
import Typography from '../Typography/Typography'


const WpBlockTypography = styled(Typography)`
  ${wpBlockStyles}
`

const WpContent = ({children}) => (
  <WpBlockTypography type="div">
    {children}
  </WpBlockTypography>
)


WpContent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WpContent
